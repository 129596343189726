<template>
  <div class="card">
    <!-- Menubar -->
    <div
      v-if="editor"
      class="card-header p-1 w-100 d-flex align-items-center position-relative flex-wrap"
    >
      <button
        title="Fett (Bold)"
        class="btn px-1 mr-1 hover"
        :class="{ 'active': editor.isActive('bold') }"
        @click="editor.chain().focus().toggleBold().run()"
      >
        <i class="far text-gray-5 fa-bold fa-md fa-fw" />
      </button>
      <button
        title="Kursiv (Italic)"
        class="btn px-1 mr-1 hover"
        :class="{ 'active': editor.isActive('italic') }"
        @click="editor.chain().focus().toggleItalic().run()"
      >
        <i class="far text-gray-5 fa-italic fa-fw" />
      </button>
      <button
        title="Unterstreichen"
        class="btn px-1 mr-1 hover"
        :class="{ 'active': editor.isActive('underline') }"
        @click="editor.chain().focus().toggleUnderline().run()"
      >
        <i class="far text-gray-5 fa-underline fa-fw" />
      </button>
      <div class="divider mr-1" />
      <button
        title="Paragraph"
        class="btn px-1 mr-1 hover"
        :class="{ 'active': editor.isActive('paragraph') }"
        @click="editor.chain().focus().setParagraph().run()"
      >
        <i class="far text-gray-5 fa-paragraph fa-fw" />
      </button>
      <button
        title="Überschrift 1"
        class="btn px-1 mr-1 hover"
        :class="{ 'active': editor.isActive('heading', { level: 1 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
      >
        <i class="far text-gray-5 fa-h1 fa-fw" />
      </button>
      <button
        title="Überschrift 2"
        :class="{ 'active': editor.isActive('heading', { level: 2 }) }"
        class="btn px-1 mr-1 hover"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
      >
        <i class="far text-gray-5 fa-h2 fa-fw" />
      </button>
      <button
        title="Überschrift 3"
        :class="{ 'active': editor.isActive('heading', { level: 3 }) }"
        class="btn px-1 mr-1 hover"
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
      >
        <i class="far text-gray-5 fa-h3 fa-fw" />
      </button>
      <button
        title="Überschrift 4"
        :class="{ 'active': editor.isActive('heading', { level: 4 }) }"
        class="btn px-1 mr-1 hover"
        @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
      >
        <i class="far text-gray-5 fa-h4 fa-fw" />
      </button>
      <div class="divider mr-1" />
      <button
        title="Punktliste"
        :class="{ 'active': editor.isActive('bulletlist')}"
        class="btn px-1 mr-1 hover"
        @click="editor.chain().focus().toggleBulletList().run()"
      >
        <i class="far text-gray-5 fa-list-ul fa-fw" />
      </button>
      <button
        title="Nummernliste"
        class="btn px-1 mr-1 hover"
        :class="{ 'active': editor.isActive('orderedList')}"
        @click="editor.chain().focus().toggleOrderedList().run()"
      >
        <i class="far text-gray-5 fa-list-ol fa-fw" />
      </button>
      <button
        title="Zitat"
        :class="{ 'active': editor.isActive('blockquote')}"
        class="btn px-1 mr-1 hover"
        @click="editor.chain().focus().toggleBlockquote().run()"
      >
        <i class="far text-gray-5 fa-quote-right fa-fw" />
      </button>
      <button
        title="Trennstrich"
        class="btn px-1 mr-1 hover"
        @click="editor.chain().focus().setHorizontalRule().run()"
      >
        <i class="far text-gray-5 fa-horizontal-rule fa-fw" />
      </button>
      <div class="divider mx-1" />
      <button
        title="Standart"
        :class="{ 'active': editor.isActive('textStyle', { color: '#545454' }) }"
        class="btn px-1 mr-1 hover"
        @click="editor.chain().focus().setColor('#545454').run()"
      >
        <i class="fas fa-circle fa-fw text-default" />
      </button>
      <button
        title="Grau"
        :class="{ 'active': editor.isActive('textStyle', { color: '#e9e9e9' }) }"
        class="btn px-1 mr-1 hover"
        @click="editor.chain().focus().setColor('#e9e9e9').run()"
      >
        <i
          class="fas fa-circle fa-fw"
          style="color:#e9e9e9;"
        />
      </button>
      <button
        title="Platinblau"
        :class="{ 'active': editor.isActive('textStyle', { color: '#285675' }) }"
        class="btn px-1 mr-1 hover"
        @click="editor.chain().focus().setColor('#285675').run()"
      >
        <i
          class="fas fa-circle fa-fw"
          style="color:#285675;"
        />
      </button>
      <button
        title="Rot"
        :class="{ 'active': editor.isActive('textStyle', { color: '#dc0c15' }) }"
        class="btn px-1 mr-1 hover"
        @click="editor.chain().focus().setColor('#dc0c15').run()"
      >
        <i
          class="fas fa-circle fa-fw"
          style="color: #dc0c15;"
        />
      </button>
      <button
        title="Grün"
        :class="{ 'active': editor.isActive('textStyle', { color: '#289a38' }) }"
        class="btn px-1 mr-1 hover"
        @click="editor.chain().focus().setColor('#289a38').run()"
      >
        <i
          class="fas fa-circle fa-fw"
          style="color:#289a38;"
        />
      </button>
      <!-- <template v-if="showLogoOption">
        <div class="divider mx-1" />
        <button
          title="Logo einfügen"
          class="btn px-1 mr-1 hover"
          @click="editor.chain().focus().setImage({ src: 'https://mobile-garantie.de/uploads/logo-mobile-garantie.svg' }).run()"
        >
          <img
            src="@/assets/img/logo-white-icon.png"
            width="20"
            height="20"
            class="logo-icon"
          >
        </button>
      </template> -->
    </div>

    <!-- Editor -->
    <div class="card-body p-2">
      <editor-content :editor="editor" />
    </div>

    <!-- Footerbar -->
    <div class="card-footer p-1 w-100 d-flex align-items-center flex-wrap">
      <button
        title="Rückgängig"
        :disabled="canUndo"
        class="btn px-1 mr-1"
        :class="{'hover': !canUndo}"
        @click="editor.chain().focus().undo().run()"
      >
        <i class="far text-gray-5 fa-undo fa-md fa-fw" />
      </button>
      <button
        title="Wiederholen"
        :disabled="canRedo"
        class="btn px-1 mr-1"
        :class="{'hover': !canRedo}"
        @click="editor.chain().focus().redo().run()"
      >
        <i class="far text-gray-5 fa-redo fa-md fa-fw" />
      </button>
      <template v-if="fileUpload">
        <div class="divider mx-1" />
        <template v-for="(file, index) in files">
          <SignedDownloadLink
            :key="file._id"
            :file="file"
          >
            <template #default="{link}">
              <span class="mx-1 px-2 py-1 bg-primary rounded">
                <a
                  :href="link"
                  :download="file.name"
                  class="py-2 text-white"
                >
                  <i class="far fa-file-pdf mr-2" />{{ file.name }}
                </a>

                <i
                  class="far fa-times text-white ml-2 cursor-pointer"
                  @click="removeFileFromList(index)"
                />
              </span>
            </template>
          </SignedDownloadLink>
        </template>
        <label
          v-if="!uploadPending"
          for="document-upload"
          class="text-primary cursor-pointer pl-2 mb-0 d-flex align-items-center"
        >
          <i class="far fa-paperclip text mr-1" /> <small>Anhang hinzufügen</small>
        </label>
        <p
          v-if="uploadPending"
          class="text-primary d-inline mb-0 pl-2"
        >
          <i class="far fa-spin fa-circle-notch mr-1" /> Anhang wird hochgeladen...
        </p>
        <input
          id="document-upload"
          ref="fileInput"
          type="file"
          multiple
          accept=".pdf"
          class="d-none "
          :disabled="uploadPending"
          @change="upload"
        >
      </template>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'
import Image from '@tiptap/extension-image'
import axios from 'axios'
import feathers from '@/api'
import SignedDownloadLink from '@/components/SignedDownloadLink.vue'

export default {
  components: {
    EditorContent,
    SignedDownloadLink
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    fileUpload: {
      type: Boolean,
      default: true
    },
    large: {
      type: Boolean,
      default: false
    },
    // showLogoOption: {
    //   type: Boolean,
    //   default: false
    // },
    fetchedFiles: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      editor: null,
      uploadPending: false,
      files: []
    }
  },
  computed: {
    canUndo () {
      if (this.editor) {
        return !this.editor.can().undo()
      } else {
        return false
      }
    },
    canRedo () {
      if (this.editor) {
        return !this.editor.can().redo()
      } else {
        return false
      }
    }
  },
  watch: {
    value (value) {
      const isSame = this.editor.getHTML() === value

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    }
  },
  mounted () {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
        TextStyle,
        Color,
        Image.configure({
          inline: true,
          HTMLAttributes: {
            class: 'logo-wysiwyg'
          }
        })
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML())
      }
    })
    if (this.fileUpload && this.fetchedFiles && this.fetchedFiles.length > 0) {
      this.files = [...this.fetchedFiles]
    }
  },
  beforeDestroy () {
    this.editor.destroy()
  },
  methods: {
    async upload (event) {
      if (this.uploadPending || !event.target.files || !event.target.files[0]) return
      try {
        this.uploadPending = true
        const toUploadFiles = []
        for (const key in event.target.files) {
          if (typeof event.target.files[key] === 'object') {
            toUploadFiles.push(event.target.files[key])
          }
        }
        const files = await Promise.all(toUploadFiles.map(async (toUploadFile) => {
          const file = await this.uploadFile(toUploadFile)
          return file
        }))
        this.files.push(...files)
        this.$emit('updateFiles', this.files.map((el) => { return el._id }))
      } catch (error) {
        console.error(error)
      } finally {
        // reset fileInput
        this.$refs.fileInput.value = ''
        if (this.$refs.fileInput.value) {
          this.$refs.fileInput.type = 'text'
          this.$refs.fileInput.type = 'file'
        }
        this.uploadPending = false
      }
    },
    async uploadFile (toUploadFile) {
      const file = await feathers.service('file').create({
        name: toUploadFile.name,
        type: toUploadFile.type
      })
      await axios.put(file.uploadUrl, toUploadFile, this.config)
      return file
    },
    removeFileFromList (index) {
      this.files.splice(index, 1)
      this.$emit('updateFiles', this.files.map((el) => { return el._id }))
    }
  }
}
</script>

<style scoped lang="scss">
.hover:hover {
  background-color: rgba(0, 0, 0, 0.12);
}
.active {
  background-color: rgba(0, 0, 0, 0.12);
}

.logo-icon {
  margin-bottom: 0.2em;
}

::v-deep .ProseMirror {
  min-height: 10em;
  &:focus {
    outline: none;
  }
}
</style>
